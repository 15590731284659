
import { Vue } from "vue-property-decorator";
import api from "@/api/killrecord.ts";

export default class extends Vue {
  infodata = {};
  previewimg = "";
  show = false;
  mounted() {
    this.fGetInfo();
  }
  async fGetInfo() {
    const res: any = await api.projectDisinfectRecordgetById(
      this.$route.params.id
    );
    if (res.code !== 0) {
      return this.$toast.fail(res.msg);
    }
    this.infodata = res.data;
  }
  previewimgfun(arg: any) {
    this.previewimg = arg;
    this.show = true;
  }
}
